import LegalLayout from "../../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../../constants/legal/privacy/regulations.md"

export default function PrivacyRegulationsPage() {
  return (
    <LegalLayout
      description="Privacy laws are in a lot of flux. Here’s info you should know."
      heading="Privacy Regulations Reference"
      source={source}
      title="Privacy Regulations"
    />
  )
}
